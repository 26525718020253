import React, { useState, useMemo } from "react";
import { graphql, useStaticQuery } from 'gatsby';
import PostCard from "./post-card/PostCard";
import Pagination from "./pagination/Pagination";
import { StaticImage } from "gatsby-plugin-image";

import * as styles from './BlogPage.module.scss';

let PageSize = 9;

const BlogPage = () => {

    const [currentPage, setCurrentPage] = useState(1);

    const data = useStaticQuery(graphql`
    query PostsQuery {
      allStrapiPost {
      edges {
        node {
            author
            content
            header
            id
            title
            createdAt
            minToRead
            urlPath
            headerImg { 
            localFile {
                publicURL
                childImageSharp {
                    gatsbyImageData
                  }
            }
        }
        }
      }
    }
    }`)
    const allPosts = data.allStrapiPost.edges.map((edge: any) => edge.node).sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return allPosts.slice(firstPageIndex, lastPageIndex);
    }, [currentPage]);


    return (
        <section className={styles.BlogSection}>
            <header className={styles.Header}>
                <StaticImage src="../../../images/BlogHeader.png" alt="mountain view" />
                <h1>Blog</h1>
            </header>
            <article className={styles.BlogsDisplay}>

                <div className={styles.BlogsWrapper}>
                    {currentTableData.map((post: any, i: any) => {
                        return (
                            <PostCard key={i} post={post} />
                        )
                    })}
                </div>
                <Pagination
                    className="pagination-bar"
                    currentPage={currentPage}
                    totalCount={allPosts.length}
                    pageSize={PageSize}
                    onPageChange={(page: any) => setCurrentPage(page)} />
            </article>
        </section>
    );
}

export default BlogPage;
