import React from "react";
import Seo from "src/components/seo";
import Layout from "src/components/layout/Layout";
import ContactFooter from "src/components/layout/ContactFooter/ContactFooter";
import Patrons from "src/components/layout/Patrons/Patrons";
import BlogPage from 'src/components/pages/blog/BlogPage';

export default function index() {

    return (
        <Layout>
            <Seo />
            <BlogPage />
            <ContactFooter showForm={true} showInfo={false} />
            <Patrons />
        </Layout>
    );
}
