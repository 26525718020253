import React from "react";
import moment from "moment";
import { Link } from "gatsby";
import * as styles from './PostCard.module.scss';
import { getImage, GatsbyImage, StaticImage, ImageDataLike } from 'gatsby-plugin-image';
import { any } from "prop-types";

type Post = {
    post: {
        id: string,
        title: string,
        header: string,
        content: string,
        author: string,
        updatedAt: string,
        minToRead: number,
        urlPath: string,
        headerImg: {
            localFile: {
                publicURL: string,
                childImageSharp: ImageDataLike
            }
        }
    }
}

const PostCard = ({ post }: Post) => {
    const postImage = getImage(post.headerImg.localFile.childImageSharp)
    return (
        <Link to={`/blog/${post.urlPath}`} className={styles.PostCard}>
            <div className={styles.ImgWrapper}>
                {postImage ? <GatsbyImage image={postImage} alt={`article picture`} /> : <StaticImage src="/blog-2355684_640.jpg" alt="mountain view" />}

                <h4>{`${post.minToRead} minut czytania`}</h4>
            </div>
            <div className={styles.TextWrapper}>
                <h3>{post.title}</h3>
                <p>{post.header}</p>
            </div>
            <footer>
                <p>{post.author}</p>
                <p>{moment(post.updatedAt).format("DD.MM.YYYY")}</p>
            </footer>
        </Link>
    );
}

export default PostCard;